.title,
.subTitle,
.description {
  font-style: normal;
  display: flex;
  margin: auto;
  text-align: center;
}

.title {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  max-width: 41rem;
  padding-bottom: 1rem;

  display: flex;
  flex-direction: column;
}

.subTitle {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1, 1875rem;
  max-width: 38rem !important;
}

.description {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 23px;
  max-width: 35rem !important;
}

.telaInicial {
  margin: 2rem 0;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.telaInicial > div {
  min-width: 400px;
}

@media only screen and (max-width: 500px) {
  .telaInicial > div {
    min-width: 300px;
  }
}

.cardsContainer{
  display: flex;
  gap: 3rem;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 220px;
  max-width: 220px;
  height: 210px;
  max-height: 210px;
  background: #ececec;
  box-shadow: -1px 1px 2px rgba(172, 172, 172, 0.2),
    1px -1px 2px rgba(172, 172, 172, 0.2),
    -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 1px 3px rgba(172, 172, 172, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(172, 172, 172, 0.5);
  border-radius: 10px;
  padding: 0.5rem;

  img {
    color: #06357a;
    width: 1.8rem;
    height: 1.8rem;
  }

  p {
    color: #06357a;
    font-weight: 700;
  }

  span {
    content: " ";
    max-width: 25%;
    margin: 3rem 0 1rem 37%;
    border-top: 1px solid #06357a;
  }

  svg{
    color: #06357a;
    transform: scale(3);
    margin: auto;
  }
}

.cardIconContainer {
  width: 1.8rem;
  height: 1.8rem;
  margin: auto;

  margin-bottom: 0.7rem;
  span {
    border-bottom: 1px solid #06357a;
    content: "";
    margin-top: 0.5rem;
  }
}

.main {
  button {
    border: none;
    display: flex;
    svg {
      margin: auto 0 auto 0.5rem;
    }
    span {
      margin-left: 1rem;
      flex: 1;
    }
  }
}

.buttons{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btnInformes{
  color: #fff;
  background-color: #0f91fe;
  background-image: linear-gradient(to bottom right, #0fc9fe, #0e72fe);
  
  transition: background-color 0.2s ;
  
  &:hover{
    color: #fff;
    background-color: #0872c9;
    background-image: linear-gradient(to bottom right, #0ca8d3, #0454c5);
  }
}