.customBox {
  background-color: #ffffff;
  border-radius: 0.5rem;
  color: #002a5d;
  text-align: center;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  svg:not(button > svg) {
    width: 6.25rem;
    height: 6.25rem;
    color: green;
    margin: 0 auto 0 auto;
  }

  p:not(.protocoloContainer > p) {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    max-width: 400px;
    margin: 1rem auto 0 auto;
  }
}

.protocoloContainer {
  background-color: #e9fafc;
  border-radius: 0.6rem;
  max-width: 300px;
  max-height: 100px;
  margin: 0 auto 0 auto;

  p {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-style: normal;
  }

  span {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 2rem;
  }
}

.protocoloFooter {
  margin-top: 1.4rem;
  display: flex;
  padding: 0;

  button {
    width: 20rem;
    height: 3.125rem;
    color: white;
    border: none;
    font-family: Roboto, sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 0.7rem;

    &:hover {
      filter: brightness(0.8);
      transition: filter 0.3s;
    }
  }

  @media only screen and (max-width: 500px) {
    button {
      width: 12rem;
      font-size: 1rem;
    }
  }

  button:first-child {
    border-radius: 0 0.5rem 0 0.5rem;
    background-color: #0f6efe;
  }

  button:last-child {
    border-radius: 0.5rem 0 0.5rem 0;
    background-color: #28a745;
    margin-left: auto;
  }
}
