.code-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.code-label {
  margin-bottom: 16px;
}
.code-inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.code-inputs input {
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: 60px;
  width: 40px;
  border-radius: 10px;
  margin: 0 4px;
  border: 2px solid #4f5b66;
  font-size: 38px;
}
.code-inputs input:focus {
  outline: none;
}
.code-inputs input:nth-child(3n) {
  margin-right: 24px;
}

@media only screen and (max-width: 500px) {
  .code-inputs input:last-child {
    margin-right: 0;
  }

  .code-inputs input {
    height: 50px;
    width: 30px;
  }
}

.verification-label {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #002a5d;
  text-align: center;
}

.verification-p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #06357a;
}

.custom-btn-link {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
}
