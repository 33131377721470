.dialogModalHeader {
  padding: 15px 20px 0px 20px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dialogModalText {
  font-size: 2rem;
  font-weight: 500;
}

.dialogModalButton {
  justify-self: end;
}

.dialogModalButton:hover {
  color: red;
}

.dialogModalhr {
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.dialogModalContent {
  padding: 20px;
  text-align: center;
  overflow: hidden;

  h1 {
    color: #002a5d;
    margin: 1rem 0 1rem 0;

    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 1.75rem;
  }

  footer {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    color: #002a5d;

    text-align: center;
    align-items: center;
    justify-content: center;

    margin-bottom: 2rem;
  }
}

.dialogModalActions {
  padding: 0 20px 15px 20px;
}

span[class$="indicatorSeparator"] {
  display: none;
}

.etapasContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.etapa {
  position: relative;

  span {
    position: absolute;
    top: 32%;
    color: white;

    font-family: Roboto, sans-serif;
    font-weight: 500;
  }
}

.firstStep {
  left: 27%;
}

.midStep {
  left: 33%;
}

.lastStep {
  left: 36%;
}

.protocoloContainer {
  background-color: #e9fafc;
  border-radius: 0.6rem;
  max-width: 500px;
  max-height: 200px;
  margin: 0 auto 0 auto;
  color: #002a5d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.7rem;

  margin-bottom: 1rem;

  p {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-style: normal;
  }

  span {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: 2rem;
  }
}

.dadosBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background: #e9fafc;
  border-radius: 8px;
}

.dadosLabel {
  font-weight: bolder;
  color: #002a5d;
  margin-bottom: 1rem;
}

.labelCollapse {
  position: relative;
  cursor: pointer;
}

.labelCollapse::after {
  content: ">";
  color: #333;
  top: -2px;
  right: -15px;
  position: absolute;
}

.labelCollapse[aria-expanded="true"]::after {
  top: 0;
  right: -16px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dadosRow {
  display: flex;
  flex-direction: row;
  gap: 0.5em;

  div:first-child {
    font-weight: bold;
  }
}

.botaoContinuarRow {
  position: relative;
  width: 100%;
  height: 50px;
}
.botaoContinuar {
  position: absolute;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 280px;
  height: 50px;
  background: #0f6efe;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 8px 0px;
  font-weight: bold;
  &:hover {
    background: #0f6efe;
    color: black;
  }
}
.botaoEnviar {
  position: absolute;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 280px;
  height: 50px;
  background: #28a745;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 8px 0px;
  font-weight: bold;
  &:hover {
    background: #6aeb88;
    color: black;
  }
}
.botaoReverDados {
  position: absolute;
  left: 0%;
  top: 0%;
  bottom: 0%;
  width: 280px;
  height: 50px;
  background: #0f6efe;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 0px 8px;
  font-weight: bold;
  &:hover {
    background: #0f6efe;
    color: black;
  }
}

.representanteBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.representanteTag {
  display: flex;
  height: 18px;
  align-items: center;
  padding: 2px 8px;

  background: #40a4ba;
  border-radius: 27px;

  font-size: 12px;
  color: white;
}

.dadosAjustaveisBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background: #e9fafc;
  border-radius: 8px;

  text-align: start;
  gap: 1rem;
}

.fieldWithError {
  border-color: red;

  div {
    border-color: red;

    &:hover {
      border-color: red;
    }
  }
}

.errorSpan {
  color: red;
  font-size: 0.8rem;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;

  button:first-child {
    margin-left: auto;
  }
}

.tituloAjuste {
  text-align: start;
  font-size: 1.4rem;
  color: #002a5d;
}

.dropZone {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    margin: 0;
  }
}

.removeFileText {
  text-decoration: underline;
  cursor: pointer;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  justify-content: center;
  align-content: center;

  .thumbInner {
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
    height: 165px;

    p {
      margin: 0;
      min-height: 1.5rem;
    }

    .img {
      display: block;
      width: auto;
      height: 95px;
    }
  }
}

.docsText {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  span {
    margin-left: 0.5rem;
  }
}

.filesList {
  display: flex;
  gap: 1.6rem;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #002a5d;
    font-size: 1.1rem;
    font-weight: bold;

    font-family: roboto, sans-serif;
  }
}
