body{
    background-color: #002A5D;
    color: #F2F2F2;
}

.mainContent{
    flex: 1;
    text-align: center;
    padding-top: 50px;
}

.main{
    width: 100%;
    height: 100%;

    header{
        display: flex;
        flex-direction: row;

        &:last-child{
            margin-left: auto;
        }
    }
}

.logoHavan{
    display: flex;
    margin-right: auto;
}

footer{
    margin-top: 2rem;

    p{
        margin-top: 0.9rem;
        font-family: Roboto, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
    }
}