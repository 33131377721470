.container{
  display: flex;
  flex-direction: column;
}

.customBox {
  max-width: 50%;
  background-color: #ffffff;
  border-radius: 0.5rem;
  color: #002A5D;
  text-align: center;
  margin: 3rem auto auto auto;
  display: flex;
  flex-direction: column;

  svg:not(button>svg){
    width: 6.25rem;
    height: 6.25rem;
    color: green;
    margin: 0 auto 0 auto;
  }

  p:not(.protocoloContainer>p){
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-style: normal;
    max-width: 400px;
    margin: 1rem auto 0 auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  header{
    display: flex;
    padding: 0;
  }
}


.botaoVoltar {
  margin-right: auto;
  width: 70px;
  height: 70px;
  background: #0f6efe;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 8px 0px;
  &:hover {
    background: #0f6efe;
    color: black;
  }
}

.mainContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input{
    max-width: 33%;
    height: 3.125rem;
  }

  *:not(h1){
    margin: 0.5rem 0 1rem 0;
  }

  span{
    max-width: 500px;
  }
}

.footerContainer{
  display: flex;
  padding: 0;

  button{
    width: 100%;
    height: 3.25rem;
    border: none;
    border-radius: 0 0 0.5rem 0.5rem;
    color: white;
    background-color: #28A745;

    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
  }
}

.resendProtocol{
  color: white;
  display: flex;
  margin: 3rem auto 0 auto;

  &:hover{
    color: white;
    filter: brightness(0.7);
  }
}

@media only screen and (max-width: 500px) {

  .mainContainer{
    font-size: 0.9rem;

    h1{
      font-size: 1.5rem;
    }
  }

  .footerContainer{
    button{
      font-size: 1rem!important;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .mainContainer{
    
    input{
      max-width: 50%;
      height: 3.125rem;
    }
  }
  .footerContainer{
    button{
      font-size: 1rem!important;
    }
  }
}