.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #002a5d;
    font-size: 1.1rem;
    font-weight: bold;

    font-family: roboto, sans-serif;
  }
}