.title,
.subTitle,
.description {
  font-style: normal;
  display: flex;
  margin: auto;
  text-align: center;
}

.title {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  max-width: 41rem;
  padding-bottom: 1rem;
}

.subTitle {
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1, 1875rem;
  max-width: 38rem !important;
}

.description {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  max-width: 35rem !important;
}

.formulario {
  margin-top: 2rem;
}

.customBox {
  background-color: #ffffff;
  border-radius: 8px;
  color: #495057;
  text-align: start;
}

.customForm {
  padding: 4em 5em 0;
}

.botaoVoltarRow {
  position: relative;
  width: 100%;
}

.botaoVoltar {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 70px;
  height: 70px;
  background: #0f6efe;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 8px 0px;
  &:hover {
    background: #0f6efe;
    color: black;
  }
}

.botaoContinuarRow {
  position: relative;
  width: 100%;
  height: 50px;
}
.botaoContinuar {
  position: absolute;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 280px;
  height: 50px;
  background: #0f6efe;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 8px 0px;
  font-weight: bold;
  &:hover {
    background: #0f6efe;
    color: black;
  }
}
.botaoEnviar {
  position: absolute;
  right: 0%;
  top: 0%;
  bottom: 0%;
  width: 280px;
  height: 50px;
  background: #28a745;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 8px 0px;
  font-weight: bold;
  &:hover {
    background: #6aeb88;
    color: black;
  }
}
.botaoReverDados {
  position: absolute;
  left: 0%;
  top: 0%;
  bottom: 0%;
  width: 280px;
  height: 50px;
  background: #0f6efe;
  opacity: 0.8;
  border: 0;
  color: #ffffff;
  border-radius: 0px 8px;
  font-weight: bold;
  &:hover {
    background: #0f6efe;
    color: black;
  }
}

.dadosBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background: #e9fafc;
  border-radius: 8px;
}

.dadosLabel {
  position: relative;
  font-weight: bolder;
  color: #002a5d;
  margin-bottom: 1rem;
  cursor: pointer;
}

.dadosLabel::after {
  content: ">";
  color: #333;
  top: -2px;
  right: -15px;
  position: absolute;
}

.dadosLabel[aria-expanded="true"]::after {
  top: 0;
  right: -16px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dadosRow {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.dadosRow :first-child {
  font-weight: bold;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

.fieldWithError {
  border-color: red;
}

.errorSpan {
  color: red;
  font-size: 0.8rem;
}

.switchContainer {
  align-items: center;
  display: flex;
  padding: 0 1.3rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 0.5rem;
    width: 0.5rem;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}

input:checked + .slider {
  background-color: #002a5d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #002a5d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1rem);
  -ms-transform: translateX(1rem);
  transform: translateX(1rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.representanteBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.representanteTag {
  display: flex;
  height: 18px;
  align-items: center;
  padding: 2px 8px;

  background: #40a4ba;
  border-radius: 27px;

  font-size: 12px;
  color: white;
}

.digito {
  min-width: 3.4375rem;
  width: 6.25rem;
  margin-left: 0.3rem;
}

.inicialButtonsBox {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.inicialButtonsBox input[type="radio"] {
  margin-right: 1rem;
}

.inicialButtonsBox > div {
  margin-bottom: 30px;
}

.obs {
  font-size: 0.8rem;
  font-weight: bold;
}

.dropZone {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;

  p {
    margin: 0;
  }
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  justify-content: center;
  align-content: center;

  .thumbInner {
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
    height: 165px;
    position: relative;

    p {
      margin: 0;
      min-height: 1.5rem;
    }

    .img {
      display: block;
      width: auto;
      height: 95px;
    }

    .removeFileText {
      cursor: pointer;
      position: absolute;
      background: red;
      width: 1rem;
      height: 1rem;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 0.7rem;
      transition: filter 0.2s;
      font-weight: bold;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }
}

.docsText {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  span {
    margin-left: 0.5rem;
  }
}

.filesList {
  display: flex;
  gap: 1.6rem;
}

@media only screen and (max-width: 500px) {
  .botaoEnviar,
  .botaoReverDados {
    width: 180px;
  }

  .dadosRow {
    flex-direction: column;

    div:last-child {
      margin-bottom: 1rem;
    }
  }

  .customForm {
    padding: 4em 4em 0;
  }

  .botaoContinuarRow {
    display: flex;

    button {
      position: unset;
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width: 750px) {
  .botaoContinuarRow {
    display: flex;
    padding: 0;
    justify-content: space-between;

    button {
      position: unset;
    }
  }
}

.notificacaoBox {
  background: #e2edfe;
  color: #06357a;

  text-align: center;

  border-radius: 15px;
  flex: 1;

  padding: 15px;
  margin-bottom: 3rem;

  .notificacaoTitle {
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  p:not(:first-of-type){
    margin: 0;
  }
}

.selectWithError > div{
  border-color: inherit;
}