.dialogModalHeader {
  padding: 15px 20px 0px 20px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dialogModalText {
  font-size: 2rem;
  font-weight: 500;
}

.dialogModalButton {
  justify-self: end;
}

.dialogModalButton:hover {
  color: red;
}

.dialogModalhr {
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.dialogModalContent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialogModalActions {
  padding: 0 20px 15px 20px;
}

span[class$="indicatorSeparator"] {
  display: none;
}

.dialogModal{
  justify-content: start;
}