.loader {
  margin: 4px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dots{
  display: flex;
  margin: auto;
  margin-top: 30px;
  height: 50px;
  background-color: none!important;
}

.dots div{
  width: 16px;
  height: 16px;
  background: #6dbcf0 !important;
  border-radius: 50%;
  margin-left: 15px;

  animation: wave 1s ease-in-out infinite alternate;
}

.dots div:nth-child(1){
  animation-delay: -0.4s;
}

.dots div:nth-child(2){
  animation-delay: -0.2s;
}

@keyframes wave{
  from{
    transform: translateY(-40%);
  }
  to{
    transform: translateY(40%);
  }
}